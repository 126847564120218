define("discourse/plugins/discourse-theme-install-button/discourse/initializers/theme-install-button", ["exports", "discourse/lib/get-owner", "discourse/lib/plugin-api", "discourse/plugins/discourse-theme-install-button/discourse/components/modal/theme-install"], function (_exports, _getOwner, _pluginApi, _themeInstall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _attachInstallButtons(el) {
    const buttons = el.querySelectorAll(".d-wrap[data-wrap=theme-install-button]");
    buttons.forEach(bt => {
      if (!bt.dataset.repoUrl) {
        return;
      }
      const buttonEl = document.createElement("button");
      buttonEl.classList.add("btn", "btn-primary");
      buttonEl.innerHTML = bt.textContent;
      buttonEl.dataset.repoUrl = bt.dataset.repoUrl;
      buttonEl.dataset.repoName = bt.dataset.repoName || "";
      buttonEl.onclick = function () {
        (0, _getOwner.getOwnerWithFallback)().lookup("service:modal").show(_themeInstall.default, {
          model: {
            repoUrl: this.dataset.repoUrl,
            repoName: this.dataset.repoName
          }
        });
      };
      bt.replaceWith(buttonEl);
    });
  }
  var _default = _exports.default = {
    name: "discourse-theme-install-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        api.decorateCookedElement(_attachInstallButtons, {
          id: "discourse-icon"
        });
      });
    }
  };
});